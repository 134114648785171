@use "@angular/material" as mat;
@use "./styles/themes/all" as themes;
@use "./styles/sizes";

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-VariableFont_slnt\,wght.ttf);
}

@include mat.core();

:root {
  @include mat.core-theme(themes.$amns-superadmin-theme);
  @include mat.button-theme(themes.$amns-superadmin-theme);
  @include mat.icon-theme(themes.$amns-superadmin-theme);
  @include mat.menu-theme(themes.$amns-superadmin-theme);
  @include mat.input-theme(themes.$amns-superadmin-theme);
  @include mat.form-field-theme(themes.$amns-superadmin-theme);
  @include mat.snack-bar-theme(themes.$amns-superadmin-theme);
  @include mat.toolbar-theme(themes.$amns-superadmin-theme);
  @include mat.card-theme(themes.$amns-superadmin-theme);
  @include mat.table-theme(themes.$amns-superadmin-theme);
  @include mat.paginator-theme(themes.$amns-superadmin-theme);
  @include mat.sort-theme(themes.$amns-superadmin-theme);
  @include mat.select-theme(themes.$amns-superadmin-theme);
  @include mat.tooltip-theme(themes.$amns-superadmin-theme);
  @include mat.tabs-theme(themes.$amns-superadmin-theme);
  @include mat.progress-bar-theme(themes.$amns-superadmin-theme);
  @include mat.datepicker-theme(themes.$amns-superadmin-theme);
  @include mat.typography-hierarchy(themes.$amns-superadmin-theme);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0;
}

html,
body {
  height: 100% !important;
}
body {
  margin: 0;
  font-family: Inter !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--mat-app-text-color) !important;
}

.errorSnackbarClass {
  --mdc-snackbar-container-color: #ff897d;
  --mdc-snackbar-supporting-text-color: #fff;
  --mdc-snackbar-container-shape: 4px;
}

.successSnackbarClass {
  --mdc-snackbar-container-color: #5e9f6e;
  --mdc-snackbar-supporting-text-color: #fff;
  --mdc-snackbar-container-shape: 4px;
}
